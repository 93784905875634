<template>
    <div id="app" class="app-class">
        <div
            v-if="!hideMobileTopBar"
            class="sidebar-container desktop-only"
        >
            <side-bar
                :missions="missions"
                class="profile-side-bar"
            />
        </div>
        <mobile-navigation
            v-if="!hideMobileTopBar"
            :missions="missions"
            class="mobile-only"
        />
        <navigation
            v-if="!hideMobileTopBar"
            :missions="displayableMissions"
            :player="player"
            class="navigation desktop-only"
        />
        <div
            :class="{'image-background': mainBackgroundExists, 'min-content-height': hideMobileTopBar, 'full-content-container': hideMobileTopBar}"
            class="content-container"
            @click="closeNavbar"
        >
            <transition
                mode="out-in"
                name="fade"
            >
                <router-view :key="path" />
            </transition>
        </div>
        <logout-modal />
        <otp-modal />
        <first-coupon-modal />
        <email-verification-modal />
        <task-end-modal />
        <challenge-end-modal />
        <free-game-modal />
        <submit-code-modal />
        <rewards-modal />
        <rewards-info-modal />
        <survey-submit-modal />
        <vr-game-intro-modal />
        <boarding-pass-consent-modal />
    </div>
</template>
<script>
    import { Client } from '@stomp/stompjs'
    import SideBar from '@/components/SideBar'
    import Navigation from '@/components/Navigation'
    import FirstCouponModal from '@/components/modals/FirstCouponModal'
    import TaskEndModal from '@/components/modals/TaskEndModal'
    import ChallengeEndModal from '@/components/modals/ChallengeEndModal'
    import EmailVerificationModal from '@/components/modals/EmailVerificationModal'
    import OtpModal from '@/components/modals/OtpModal'
    import SubmitCodeModal from '@/components/modals/SubmitCodeModal'
    import RewardsModal from '@/components/modals/RewardsModal'
    import RewardsInfoModal from '@/components/modals/RewardsInfoModal'
    import SurveySubmitModal from '@/components/modals/SurveySubmitModal'
    import VrGameIntroModal from '@/components/modals/VrGameIntroModal'
    import FreeGameModal from '@/components/modals/FreeGameModal'
    import LogoutModal from '@/components/modals/LogoutModal'
    import BoardingPassConsentModal from '@/components/modals/BoardingPassConsentModal'
    import deviceCheck from '@/utils/device-check'
    import MobileNavigation from '@/components/MobileNavigation'
    import couponMixin from '@/mixins/coupon-mixin'
    import MySocialPassWidget from '@/utils/MySocialPassWidget'
    import boardingPassMixin from '@/mixins/boarding-pass-mixin'

    export default {
        name: 'App',
        components: {
            SideBar,
            Navigation,
            TaskEndModal,
            ChallengeEndModal,
            SubmitCodeModal,
            SurveySubmitModal,
            RewardsInfoModal,
            RewardsModal,
            EmailVerificationModal,
            OtpModal,
            FreeGameModal,
            MobileNavigation,
            FirstCouponModal,
            VrGameIntroModal,
            LogoutModal,
            BoardingPassConsentModal
        },
        // metaInfo() {
        //     return {
        //         title: 'Welcome to FRAwards',
        //         meta: [
        //             { name: 'robots', content: 'index, follow' },
        //             { name: 'title', content: 'Welcome to FRAwards' },
        //             { name: 'description', content: 'Win prizes and discounts on the airport by playing missions' },
        //             { property: 'og:url', content: 'https://frawards.fra-alliance.com/web/welcome' },
        //             { property: 'og:type', content: 'website' },
        //             { property: 'og:image', content: 'https://s3.eu-west-1.amazonaws.com/games.mpassltd.com/img/logo/0020817b-75ca-4994-85b5-c099573a2e30-fra-ss.png' },
        //             { property: 'og:title', content: 'Welcome to FRAwards' },
        //             { property: 'og:description', content: 'Win prizes and discounts on the airport by playing missions' }
        //         ]
        //     }
        // },
        mixins: [couponMixin, boardingPassMixin],
        data() {
            return {
                heartbeatInterval: null,
                client: null,
                boardingPassesFlightIds: [],
                flightPostInterval: null,
                boardingPassIds: []
            }
        },
        computed: {
            missions() {
                return this.$store.getters.getMissions
            },
            displayableMissions() {
                return this.missions.filter(mission => {
                    return !mission.locked ||
                        (mission.locked && new Date(mission.startDate) >= new Date())
                })
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            path() {
                return this.$route.path
            },
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            theming() {
                return this.$store.getters.getTheming
            },
            branding() {
                return this.$store.getters.getBranding
            },
            mainBackgroundExists() {
                return this.$store.getters.mainBackgroundExists
            },
            isLeaderboardEnabled() {
                return this.$store.getters.isLeaderboardEnabled
            },
            hideMobileTopBar() {
                return this.$route.name === 'tasks' || this.$route.name === 'submit-code' || this.$route.name === 'Challenge Game' || this.$route.path === '/incoming' || this.$route.path === '/external-leaderboard' || !this.isLoggedIn
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            isGame() {
                if (!this.task || !this.task.taskType) {
                    return false
                }
                return this.task.taskType.alias.startsWith('GAME')
            },
            hideCopyright() {
                return this.$route.name === 'submit-code' || this.isGame
            },
            isRewardsEnabled() {
                return this.$store.getters.isRewardsEnabled
            },
            couponNeededPoints() {
                return this.$store.getters.getCouponNeededPoints
            },
            couponModalshown() {
                return this.$store.getters.getCouponModalShown
            },
            hasArenaEnabled() {
                return this.player && this.player?.challengesParticipation
            },
            isBoardingScanEnabled() {
                return this.$store.getters.isBoardingScanEnabled
            }
        },
        watch: {
            player: {
                handler(newVal) {
                    if (newVal) {
                        this.checkIfWonCoupon(newVal)
                        this.setBoardingIds(newVal)
                    }
                },
                deep: true,
                immediate: true
            },
            isLoggedIn: {
                handler() {
                    this.initData()
                }
            },
            '$route.path': {
                handler() {
                    this.initData()
                }
            },
            theming: {
                handler() {
                    this.setInitialCssVars()
                },
                immediate: true,
                deep: true
            },
            isLeaderboardEnabled: {
                handler(newVal) {
                    if (newVal) {
                        this.$store.dispatch('fetchRanks')
                    }
                },
                immediate: true
            },
            boardingPassesFlightIds: {
                handler(newVal, oldVal) {
                    if (!this.isBoardingScanEnabled || !this.isLoggedIn) {
                        return
                    }
                    if (!newVal || newVal.length === 0 || !this.config.wsUrl) return

                    if (this.client === null) {
                        this.initWebsocketClient()
                    } else {
                        const diff = newVal.filter(flightId => !oldVal.includes(flightId))
                        this.initiateSubscribers(diff)
                    }
                },
                deep: true,
                immediate: true
            }
        },
        beforeCreate() {
            const deviceType = deviceCheck.isMobileOrTablet()
            this.$store.commit('setDeviceType', deviceType ? 'mobile' : 'desktop')
        },
        created() {
            this.initData()
            const recaptcha = this.$recaptchaInstance
            if (recaptcha) {
                recaptcha.hideBadge()
            }
        },
        mounted() {
            setTimeout(() => {
                const spinner = document.getElementById('spinner')
                if (spinner) {
                    spinner.remove()
                }
            }, 500) // In case of slow internet check if we need to increase the timeout
        },
        beforeDestroy() {
            clearInterval(this.flightPostInterval)
            if (this.client) {
                this.client.deactivate(() => {
                    console.log('disconnected')
                })
            }
        },
        methods: {
            initData() {
                if (this.isLoggedIn) {
                    this.initMySocialChatWidget()
                    this.$store.dispatch('initConfig')
                    this.initAuthorizedDispatchers()
                }
            },
            initAuthorizedDispatchers() {
                this.$store.dispatch('refreshMissions')
                this.$store.dispatch('refreshCurrentUser')
                if (this.hasArenaEnabled) {
                    this.$store.dispatch('fetchPendingChallengeSessions')
                }
            },
            initWebsocketClient() {
                if (this.isBoardingScanEnabled && this.isLoggedIn && this.client !== null) {
                    return
                }
                this.client = new Client({
                    brokerURL: this.config.wsUrl,
                    connectHeaders: {
                        'Aviation-Api-Key': '12345'
                    },
                    onConnect: () => this.initiateSubscribers(this.boardingPassesFlightIds),
                    onStompError: this.onStompError,
                    onWebSocketError: this.onStompError,
                    onWebSocketClose: this.onClose,
                    onUnhandledFrame: this.onUnhandledFrame,
                    heartbeatIncoming: 0,
                    heartbeatOutgoing: 1000,
                    debug: (str) => console.log('debug', str)
                })
                this.client.activate()
            },
            initiateSubscribers(boardingPassesFlightIds) {
                console.log('socket connected!')
                boardingPassesFlightIds.forEach((flightId) => {
                    this.client.subscribe(`/events/my-flights/${flightId}`, (resp) => {
                        this.websocketDisplayNotification(JSON.parse(resp.body))
                    })
                })
            },

            setBoardingIds(player) {
                this.boardingPassesFlightIds = player?.boardingPasses?.map(flight => flight.flightSearchId)
                this.boardingPassIds = player?.boardingPasses?.map(flight => flight.id)
            },
            onUnhandledFrame(frame) {
                console.log(frame)
            },
            onStompError(error) {
                console.log('error', error)
            },
            onClose(e) {
                console.log('close', e)
            },
            setInitialCssVars() {
                const bodyStyles = document.body.style
                if (this.theming.fontFamily) {
                    bodyStyles.setProperty('--font-family', `${this.theming.fontFamily}, sans-serif`)
                }
                if (this.theming.primaryColor) {
                    bodyStyles.setProperty('--primary-color', this.theming.primaryColor)
                }
                if (this.theming.secondaryColor) {
                    bodyStyles.setProperty('--secondary-color', this.theming.secondaryColor)
                    bodyStyles.setProperty('--mission-history-bg-color', this.theming.secondaryColor)
                }
                if (this.theming.lightSecondaryColor) {
                    bodyStyles.setProperty('--light-secondary-color', this.theming.lightSecondaryColor)
                }
                if (this.theming.infoColor) {
                    bodyStyles.setProperty('--info-color', this.theming.infoColor)
                }
                if (this.theming.logoHighlightColor) {
                    bodyStyles.setProperty('--logo-highlight-color', this.theming.logoHighlightColor)
                }
                if (this.theming.lightHighlightColor) {
                    bodyStyles.setProperty('--light-highlight-color', this.theming.lightHighlightColor)
                    bodyStyles.setProperty('--mission-history-border-color', this.theming.lightHighlightColor)
                }
                if (this.theming.correct) {
                    bodyStyles.setProperty('--correct', this.theming.correct)
                }
                if (this.theming.error) {
                    bodyStyles.setProperty('--error', this.theming.error)
                }
                if (this.theming.mainBackgroundColor) {
                    bodyStyles.setProperty('--main-background-color', this.theming.mainBackgroundColor)
                }
                if (this.theming.backgroundImageUrl) {
                    bodyStyles.setProperty('--background-image-url', 'url(' + this.theming.backgroundImageUrl + ')')
                }
                if (this.theming.landingPageBackground) {
                    bodyStyles.setProperty('--landing-bg-image-url', 'url(' + this.theming.landingPageBackground + ')')
                }
                if (this.theming.mainContentText) {
                    bodyStyles.setProperty('--main-content-text', this.theming.mainContentText)
                }
                if (this.theming.loginFormsBackground) {
                    bodyStyles.setProperty('--login-forms-background', this.theming.loginFormsBackground)
                }
                if (this.theming.formErrorColor) {
                    bodyStyles.setProperty('--form-error-color', this.theming.formErrorColor)
                }
                if (this.theming.progressBar) {
                    bodyStyles.setProperty('--progress-bar', this.theming.progressBar)
                }
                if (this.theming.sideBarBackground) {
                    bodyStyles.setProperty('--sidebar-background', this.theming.sideBarBackground)
                }
                if (this.theming.sideBarText) {
                    bodyStyles.setProperty('--sidebar-text', this.theming.sideBarText)
                }
                if (this.theming.sideBarBtnBackgroundColor) {
                    bodyStyles.setProperty('--sidebar-btn-bg-color', this.theming.sideBarBtnBackgroundColor)
                }
                if (this.theming.sideBarBtnTextColor) {
                    bodyStyles.setProperty('--sidebar-btn-text-color', this.theming.sideBarBtnTextColor)
                }
                if (this.theming.sideBarHighlightColor) {
                    bodyStyles.setProperty('--sidebar-highlight-color', this.theming.sideBarHighlightColor)
                }
                if (this.theming.sideBarHighlightText) {
                    bodyStyles.setProperty('--sidebar-highlight-text-color', this.theming.sideBarHighlightText)
                }
                if (this.theming.navigationBackground) {
                    bodyStyles.setProperty('--navigation-background', this.theming.navigationBackground)
                }
                if (this.theming.navigationText) {
                    bodyStyles.setProperty('--navigation-text', this.theming.navigationText)
                }
                if (this.theming.navigationIconColor) {
                    bodyStyles.setProperty('--navigation-icon-color', this.theming.navigationIconColor)
                }
                if (this.theming.navigationHoverColor) {
                    bodyStyles.setProperty('--navigation-hover-color', this.theming.navigationHoverColor)
                }
                if (this.theming.homeTitleText) {
                    bodyStyles.setProperty('--home-title-color', this.theming.homeTitleText)
                }
                if (this.theming.homeSubTitleText) {
                    bodyStyles.setProperty('--home-subtitle-color', this.theming.homeSubTitleText)
                }
                if (this.theming.missionTitleText) {
                    bodyStyles.setProperty('--mission-title-color', this.theming.missionTitleText)
                }
                if (this.theming.missionSubTitleText) {
                    bodyStyles.setProperty('--mission-subtitle-color', this.theming.missionSubTitleText)
                }
                if (this.theming.missionButtonColor) {
                    bodyStyles.setProperty('--mission-button-color', this.theming.missionButtonColor)
                }
                if (this.theming.missionButtonTextColor) {
                    bodyStyles.setProperty('--mission-button-text-color', this.theming.missionButtonTextColor)
                }
                if (this.theming.missionBackgroundColor) {
                    bodyStyles.setProperty('--mission-background-color', this.theming.missionBackgroundColor)
                }
                if (this.theming.missionHistoryBackground) {
                    bodyStyles.setProperty('--mission-history-bg-color', this.theming.missionHistoryBackground)
                }
                if (this.theming.missionHistoryBorder) {
                    bodyStyles.setProperty('--mission-history-border-color', this.theming.missionHistoryBorder)
                }
                if (this.theming.missionHistoryTexts) {
                    bodyStyles.setProperty('--mission-history-text-color', this.theming.missionHistoryTexts)
                }
                if (this.theming.arenaPageBackground) {
                    bodyStyles.setProperty('--arena-bg', 'url(' + this.theming.arenaPageBackground + ')')
                }
                if (this.theming.arenaMainTextColor) {
                    bodyStyles.setProperty('--arena-main-text-color', this.theming.arenaMainTextColor)
                }
                if (this.theming.arenaHighlightColor) {
                    bodyStyles.setProperty('--arena-highlight-color', this.theming.arenaHighlightColor)
                }
                if (this.theming.arenaRandomBtnBgColor) {
                    bodyStyles.setProperty('--arena-random-btn-bg-color', this.theming.arenaRandomBtnBgColor)
                }
                if (this.theming.arenaRandomBtnTextColor) {
                    bodyStyles.setProperty('--arena-random-btn-text-color', this.theming.arenaRandomBtnTextColor)
                }
                if (this.theming.arenaChallengeBtnBgColor) {
                    bodyStyles.setProperty('--arena-challenge-btn-bg-color', this.theming.arenaChallengeBtnBgColor)
                }
                if (this.theming.arenaChallengeBtnTextColor) {
                    bodyStyles.setProperty('--arena-challenge-btn-text-color', this.theming.arenaChallengeBtnTextColor)
                }
                if (this.theming.arenaInvitationBgColor) {
                    bodyStyles.setProperty('--arena-invitation-bg-color', this.theming.arenaInvitationBgColor)
                }
                if (this.theming.arenaInvitationTitleTextColor) {
                    bodyStyles.setProperty('--arena-invitation-title-color', this.theming.arenaInvitationTitleTextColor)
                }
                if (this.theming.arenaInvitationSubtitleTextColor) {
                    bodyStyles.setProperty('--arena-invitation-subtitle-color', this.theming.arenaInvitationSubtitleTextColor)
                }
                if (this.theming.surveyVeryPoor) {
                    bodyStyles.setProperty('--survey-very-poor', this.theming.surveyVeryPoor)
                }
                if (this.theming.surveyPoor) {
                    bodyStyles.setProperty('--survey-poor', this.theming.surveyPoor)
                }
                if (this.theming.surveyAcceptable) {
                    bodyStyles.setProperty('--survey-acceptable', this.theming.surveyAcceptable)
                }
                if (this.theming.surveyVeryGood) {
                    bodyStyles.setProperty('--survey-very-good', this.theming.surveyVeryGood)
                }
                if (this.theming.surveyExcellent) {
                    bodyStyles.setProperty('--survey-excellent', this.theming.surveyExcellent)
                }
                if (this.theming.leaderboardTop5BackgroundColor) {
                    bodyStyles.setProperty('--leaderboard-top-5-background', this.theming.leaderboardTop5BackgroundColor)
                }
                if (this.theming.leaderboardTop5TextColor) {
                    bodyStyles.setProperty('--leaderboard-top-5-text', this.theming.leaderboardTop5TextColor)
                }
                if (this.theming.leaderboardLast5BackgroundColor) {
                    bodyStyles.setProperty('--leaderboard-last-5-background', this.theming.leaderboardLast5BackgroundColor)
                }
                if (this.theming.leaderboardLast5TextColor) {
                    bodyStyles.setProperty('--leaderboard-last-5-text', this.theming.leaderboardLast5TextColor)
                }
                if (this.theming.leaderboardCurrentPlayerText) {
                    bodyStyles.setProperty('--leaderboard-current-player-text', this.theming.leaderboardCurrentPlayerText)
                }
                if (this.theming.leaderboardCurrentPlayerBackground) {
                    bodyStyles.setProperty('--leaderboard-current-player-background', this.theming.leaderboardCurrentPlayerBackground)
                }
                if (this.theming.leaderboardStarColor) {
                    bodyStyles.setProperty('--star-color', this.theming.leaderboardStarColor)
                }
            },
            initMySocialChatWidget() {
                if (process.env.VUE_APP_SHOW_MYSOCIAL_PASS !== 'true') {
                    return
                }

                MySocialPassWidget.initWidget()

                window.mysocialpassWidget = {
                    inboxId: 115728338275185,
                    text: 'Καλωσήρθες! 👋 Κάνε μου μια ερώτηση μέσω',
                    image: 'https://chat.mysocialpass.com/widget/astypal.png'
                }
            },
            closeNavbar() {
                const toggler = document.querySelector('#navbarToggler')
                if (toggler && toggler.classList.contains('not-collapsed')) {
                    toggler.click()
                }
            }
        }
    }
</script>

<style lang="scss">
@import "~@/assets/styles/global.css";

// * {
// scrollbar-width: thin;
// scrollbar-color: gray gray;
// }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: rgb(108 117 125 / 45%);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--logo-highlight-color);
    border-radius: 10px;
    height: 10px;
    border: 1px solid var(--logo-highlight-color);
}

#app.is-loading {
    overflow-y: hidden !important;
    height: 100vh;
}

.desktop-only {
    display: none !important;
}

.mobile-only {
    display: block !important;
}

.content-container {
    position: relative;
    min-height: calc(100vh - 66px);
    width: 100%;
    background: var(--main-background-color);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    flex-direction: column;
}

.min-content-height {
    min-height: 100vh !important;
}

.grecaptcha-badge {
    display: none;
}

.image-background {
    background: linear-gradient(173deg, rgb(0 0 0 / 5%) 0%, rgb(0 0 0 / 5%) 100%), var(--background-image-url);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

.fb-overrider {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 2147483647;
    background: var(--logo-highlight-color);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: slide-up 0.5s ease-in-out;
}

@keyframes slide-up {
    0% {
        transform: translateY(100px);
    }

    100% {
        transform: translateY(0);
    }
}

.fb-overrider img {
    width: 40px;
    height: 40px;
}

@media screen and (min-width: 992px) {
    .desktop-only {
        display: block !important;
    }

    .mobile-only {
        display: none !important;
    }

    .app-class {
        display: grid;
        grid-template-columns: 25% 75%;
        grid-template-rows: 70px 1fr;
    }

    .sidebar-container {
        grid-column: 1;
        grid-row: span 2;
    }

    .navigation .desktop-only {
        grid-column: 2;
        grid-row: 1;
    }

    .content-container {
        min-height: calc(100vh - 70px);
        grid-column: 2;
        grid-row: 2;
    }

    .full-content-container {
        grid-column: 1 / 3 !important;
        grid-row: 1 / 3 !important;
    }
}

</style>
