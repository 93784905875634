import moment from 'moment'

export default {
    getDurationFromTime(from) {
        const now = moment()
        const fromMoment = moment(from)

        return moment.duration(now.diff(fromMoment)).asHours()
    },
    getDurationToTime(to) {
        const now = moment()
        const toMoment = moment(to)

        return moment.duration(toMoment.diff(now)).asHours()
    },
    getCurrentDateAsNumber() {
        const now = new Date()
        const start = new Date(now.getFullYear(), 0, 0)
        const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset() * 60 * 1000))
        const oneDay = 1000 * 60 * 60 * 24
        return Math.floor(diff / oneDay)
    }
}
