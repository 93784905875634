import restApi from '@/utils/rest-api'

export default {
    uploadDecodedPass(content) {
        return restApi.post('boarding-passes/search-by-decoded-bp', { content })
    },
    updateUserDataConsent() {
        return restApi.post('boarding-passes/user-consent')
    },
    getFlightStatus(flightIds) {
        return restApi.post('boarding-passes', flightIds)
    },
    deleteBoardingPasses(flightList) {
        return restApi.delete('boarding-passes/flights', flightList)
    }
}
