import dateUtils from '@/utils/date-utils'
import store from '@/store'
export default {

    updateBoardingPassesFlights(flights) {
        store.commit('setUpcomingFlights', flights?.filter(flight => !flight.flightStatus))
        const currentDate = dateUtils.getCurrentDateAsNumber()

        const todayFlights = flights?.filter(flight => {
            return flight.flightStatus && flight.dateOfFlight === currentDate
        })
        if (todayFlights) {
            store.commit('setTodayFlights', todayFlights)
        }
    }

}
