import emitter from '@/utils/emitter'
export default {
    methods: {
        websocketDisplayNotification(flight) {
            let finalDisplayMessages = []
            let flightNumber = ''

            flightNumber = `${flight.flightDTO.flightNumber}`

            emitter.emit('statusChange')
            if (flight.eventType === 'status_change') {
                if (flight.flightDTO.flightStatus === 'not_found') {
                    finalDisplayMessages.push(`Your flight is not found, this could be due to your boarding pass being too old, or next year\'s`)
                }
                finalDisplayMessages.push(`Your flight has now status: ${flight.flightDTO.flightStatus}`)
            }

            if (flight.eventType === 'dep_gate_announced') {
                finalDisplayMessages.push(`Your flight is scheduled to depart from Gate ${flight.flightDTO.depGate}`)
            }

            if (flight.eventType === 'dep_terminal_announced') {
                finalDisplayMessages.push(`Your flight is scheduled to depart from Terminal ${flight.flightDTO.depTerminal}`)
            }
            if (flight.eventType === 'dep_gate_changed') {
                finalDisplayMessages.push(`Your flight has updated to depart from Gate ${flight.flightDTO.depGate}`)
            }

            if (flight.eventType === 'dep_terminal_changed') {
                finalDisplayMessages.push(`Your flight has updated to depart from Terminal ${flight.flightDTO.depTerminal}`)
            }

            finalDisplayMessages.forEach(finalDisplayMessage => {
                this.displayNotificationToast(
                    finalDisplayMessage,
                    `Flight ${flightNumber}`
                )
            })
        }
    }
}
